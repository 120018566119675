<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="width: 100%;">
    <el-card class="box-card" style="text-align: left;">
      <div slot="header" class="clearfix">
        <span>商户审核</span>
      </div>
      <div>

        <br/>

        <el-form :inline="true" label-width="100px">
          <el-form-item label="商户名">
            <el-input size="small" v-model="merName" clearable></el-input>
          </el-form-item>

          <el-form-item label="审核状态">
            <el-select size="small" v-model="status" clearable filterable>
              <el-option v-for="item in statusList" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="padding-left:20px;">
            <el-button type="primary" size="small" style="margin-right:10px;" @click="getList">查询</el-button>
          </el-form-item>
        </el-form>

        <br/>

        <el-row>
          <router-link :to="{path: `/admin/merchant_verify/new`}">
            <el-button type="primary" size="small">新增</el-button>
          </router-link>
        </el-row>

        <br/>

        <el-table :data="list" stripe style="margin-top:10px;">
          <el-table-column type="index" label="序号" width="50" header-align="center" align="center"/>
          <el-table-column prop="merName" width="300" label="商户名" header-align="center" align="center">
            <template slot-scope="scope">
              <span class="link" @click="watchItem(scope.row.id)">{{ scope.row.merName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="merId" width="300" label="商铺号" header-align="center" align="center"/>
          <el-table-column prop="statusMessage" width="150" label="审核状态" header-align="center" align="center"/>
          <el-table-column prop="rate" label="手续费率(%)" header-align="center" align="center"/>
          <el-table-column prop="parentRate" label="抽成比例(%)" header-align="center" align="center"/>
          <el-table-column prop="intoAccountDuration" label="清分账期(天)" header-align="center" align="center"/>
          <el-table-column prop="autoReceiptLabel" label="是否自动到账" header-align="center" align="center"/>
          <el-table-column label="操作" header-align="center" align="center">
            <template slot-scope="scope">
              <el-row>
                <el-button type="success" size="mini" @click="checkItem(scope.row)" class="m-2"
                           v-if="hasPermission(scope.row,1)&&scope.row.operationPermissionList[0]">审核
                </el-button>
              </el-row>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination style="margin: 10px 0 0 -10px;" background :page-size="pageSize" layout="total,prev, pager, next"
                       :total="total" :current-page="currentPage" @current-change="handleCurrentChange"/>
      </div>
    </el-card>

    <el-dialog :visible.sync="dialogVisible" :before-close="clearValue">
      <el-form :inline="false" class="form" style="text-align: left;" label-width="120px" :model="item"
               :rules="paramsRules" ref="form1">
        <el-form-item label="审核结果" class="form-item" prop="result">
          <el-radio v-model="item.result" :label=true>同意</el-radio>
          <el-radio v-model="item.result" :label=false>不同意</el-radio>
        </el-form-item>

        <el-form-item label="手续费率(%)" class="form-item" prop="rate" v-if="item.result&&edit_rate">
          <el-input v-model="item.rate" placeholder="请填写手续费率" size="small"></el-input>
          <span style="color: red;font-size: 12px;">输入1则代表手续费率为1%</span>
        </el-form-item>

        <el-form-item label="抽成比例(%)" class="form-item" prop="parentRate" v-if="item.result">
          <el-input v-model="item.parentRate" placeholder="请填写抽成比例" size="small"></el-input>
          <span style="color: red;font-size: 12px;">输入1则代表抽成比例为1%</span>
        </el-form-item>

        <el-form-item label="清分账期(天)" class="form-item" prop="intoAccountDuration" v-if="item.result&&has_permission">
          <el-input-number v-model="item.intoAccountDuration" :min="1" label="输入1则代表清分账期为1天"></el-input-number>
          <span style="color: red;font-size: 12px;padding-left: 10px;">输入1则代表清分账期为1天</span>
        </el-form-item>
        <el-form-item label="是否自动到账" class="form-item" prop="isAutoReceive">
          <el-checkbox v-model="item.autoReceipt"></el-checkbox>
        </el-form-item>
        <el-form-item label="问题反馈" class="form-item" prop="verifyResult" v-if="!item.result">
          <el-input v-model="item.verifyResult" placeholder="请填写问题反馈" size="small" type="textarea"></el-input>
        </el-form-item>

        <el-form-item style="text-align:left;">
          <el-button type="button" class="el-button--primary" @click="verify" style="margin-right:20px;">提交</el-button>
          <el-button type="button" class="el-button--primary" @click="clearValue" style="margin-right:20px;">取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index.vue",
  data() {
    const check = (rule, value, callback) => {
      if (this.item.result) {
        if (!this.item.parentRate) {
          callback(new Error('请输入费率'));
        } else {
          callback()
        }
      }
      let reg = new RegExp("(^[1-9]([0-9]+)?(\\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\\.[0-9]([0-9])?$)");
      if (!reg.test(value)) {
        callback(new Error('请输入正确额格式,可保留两位小数'));
      } else {
        callback();
      }
    };
    return {
      fullscreenLoading: false,
      list: [],
      check,
      currentPage: 1,
      pageSize: 20,
      statusList:null,
      type: null,
      status: null,
      id: localStorage.getItem("admin_id"),
      merName: null,
      total: 0,
      item: {
        result: true,
        parentRate: null,
        verifyResult: null,
        intoAccountDuration: null,
        id: null,
        parentId: null,
        rate: null
      },
      paramsRules: {
        parentRate: [
          {validator: check, trigger: 'blur'}
        ],
        rate: [
          {validator: check, trigger: 'blur'}
        ]
      },
      dialogVisible: false,
      has_permission: false,
      edit_rate: false,
    }
  },
  mounted() {
    this.getList()
    this.getStatusList();
  },
  methods: {
    hasIntoAccountDurationPermission() {
      let parentId = localStorage.getItem("merchantId");
      let level = localStorage.getItem("level");
      if (this.item.parentId === parentId) {
        this.has_permission = true;
      } else {
        this.has_permission = false;
      }

      if (level === '1') {
        this.edit_rate = true;
      } else {
        this.edit_rate = false;
      }

      if (this.edit_rate) {
        this.item.rate = 0.6
      }
    },
    checkItem(item) {
      this.item.id = item.id;
      this.item.parentId = item.parentId;
      this.hasIntoAccountDurationPermission();
      this.dialogVisible = true;
    },
    clearValue() {
      this.item = {
        result: true,
        parentRate: null,
        verifyResult: null,
        intoAccountDuration: null,
        autoReceipt: null,
        id: null,
        parentId: null,
        rate: null
      }
      this.dialogVisible = false;
      this.edit_rate = false;
      this.has_permission = false;
      this.getList();
    },
    hasPermission(item, type) {
      let level = localStorage.getItem("level");
      let merchant_id = localStorage.getItem("merchantId");
      if (item.id === merchant_id) {
        return false;
      } else {
        switch (type) {
          case 1:
            if (level === '1' || merchant_id === item.parentId) {
              return true;
            } else {
              return false;
            }
          case 2:
            if (merchant_id === item.parentId) {
              return true;
            } else {
              return false;
            }
        }
      }
    },
    verify() {
      let _this = this
      let params = {
        result: _this.item.result,
        parentRate: _this.item.parentRate,
        verifyResult: _this.item.verifyResult,
        intoAccountDuration: _this.item.intoAccountDuration,
        autoReceipt: _this.item.autoReceipt,
        id: _this.item.id,
        parent_merchant_id: localStorage.getItem("merchantId"),
        rate: _this.item.rate
      }
      this.$refs.form1.validate((valid) => {
        if (valid) {
          _this.fullscreenLoading = true
          adminHttp.post('/backend/merchant/checkMerchant', params).then(result => {
            if (result && result.code === 200) {
              _this.$message.success("提交成功")
              _this.clearValue();
            } else {
              _this.$message.error(result.message)
            }
          }).catch(error => {
            _this.$message.error('请求失败')
          }).finally(() => {
            _this.fullscreenLoading = false
          })
        }
      })
    },
    getList() {
      let _this = this
      let params = {
        page: _this.currentPage - 1,
        page_size: _this.pageSize,
        merName: _this.merName,
        type: _this.type,
        status: _this.status,
      }
      _this.fullscreenLoading = true
      adminHttp.post('/backend/merchant/queryMerchantDraftList', params).then(result => {
        if (result && result.code === 200) {
          if (result.data !== null) {
            _this.list = [];//result.data.content;
            // console.log(result.data.content)
            for (let item of result.data.content){
              if (item.autoReceipt === true){
                item.autoReceiptLabel = "是";
              }else{
                item.autoReceiptLabel = "否";
              }
              _this.list.push(item);
            }
            _this.total = result.data.totalElements;
          } else {
            _this.list = [];
            _this.total = 0;
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    getStatusList() {
      let _this = this
      _this.fullscreenLoading = true
      adminHttp.post('/backend/merchant/getMerchantVerifyStatusList').then(result => {
        if (result && result.code === 200) {
          _this.statusList = result.data.statusDraftList;
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.$nextTick(() => {
        this.getList()
      })
    },
    watchItem(id) {
      this.$router.push({path: `/admin/merchant_verify/watch/${id}`})
    }
  }
}
</script>

<style scoped>
.link {
  text-decoration: underline;
  color: blue;
}
</style>